<template>
	<div>
		<q-dialog
			v-model="search_dialog"
			transition-show="scale"
			transition-hide="scale"
			@hide="closeDialog"
		>
			<q-card class="q-pa-md" style="width: 700px; max-width: 80vw">
				<q-card-section class="q-pa-none">
					<div class="text-h6">Filter users</div>
				</q-card-section>
				<q-card-section class="q-pa-none">
					<q-item tag="label">
						<q-item-section>
							<q-item-label>Only show results where filtered field is empty</q-item-label>
						</q-item-section>
						<q-item-section avatar>
							<q-toggle v-model="search_by_missing_value" />
						</q-item-section>
					</q-item>
					<div style="min-width:200px" class="q-px-md q-py-sm">
						<q-group-select
							v-model="field_to_search"
							:options="field_to_search_options"
							:label="'Filter users by:'"
							:hint="'Select 1 option'"
						/>
						<q-select
							v-if="field_to_search && selected_field_type == 'select_field' &&
								(!search_by_missing_value ||
									(search_by_missing_value && field_to_search.option_type == 'data_field')
								)"
							v-model="search_value"
							:options="options_for_select_field"
						/>
						<q-input
							v-if="field_to_search && selected_field_type == 'input_field' && !search_by_missing_value"
							v-model="search_value"
						/>
						<div
							class="q-gutter-sm"
							v-if="field_to_search && selected_field_type == 'radio_field'"
						>
							<q-radio v-model="search_value" :val="radio_option_1" :label="radio_option_1" />
							<q-radio v-model="search_value" :val="radio_option_2" :label="radio_option_2" />

						</div>

						<q-input
							v-if="is_extra_input && !search_by_missing_value"
							v-model="search_value_extra_input"
						/>
					</div>
				</q-card-section>
				<q-card-actions align="right" class="bg-white text-primary">
					<q-btn flat label="Cancel" @click="closeDialog" />
					<q-btn flat label="Apply filters" @click="applyFilters"/>
				</q-card-actions>
			</q-card>
		</q-dialog>
	</div>
</template>

<script>

import { mapGetters } from "vuex";
import QGroupSelect from '@/components/utils/QGroupSelect.vue'

export default {
	name: 'SearchDialog',
	components: { QGroupSelect },
	props: {
	},
	data() {
		return {
			search_dialog: true,
			search_by_missing_value: false,
			field_to_search: null,
			field_to_search_options: [],
			options_for_select_field: [],
			selected_field_type: null,
			radio_option_1: null,
			radio_option_2: null,
			is_extra_input: false,
			search_value: null,
			search_value_extra_input: null
		}
	},
	computed: {
		...mapGetters(['logged_user'])
	},
	methods: {
		closeDialog(){
			this.search_dialog = false
			this.$emit('closeDialog')
		},
		applyFilters(){
			if (!this.search_by_missing_value){
				if (!this.search_value || (this.is_extra_input && !this.search_value_extra_input)){
					this.$q.notify({
						timeout: 6700,
						message: "You must select a filter to apply.",
						actions: [
							{
								label: 'Dismiss',
								color: 'primary',
							},
						],
					})
					return
				}
			}else{
				if (!this.field_to_search ||
					(this.field_to_search.option_type == "data_field" && !this.search_value)
				){
					this.$q.notify({
						timeout: 6700,
						message: "You must select a filter to apply.",
						actions: [
							{
								label: 'Dismiss',
								color: 'primary',
							},
						],
					})
					return
				}
			}
			let field = this.changeToSystemField(this.search_value_extra_input ? this.search_value: this.field_to_search)
			let value = this.changeToSystemValue(this.search_value_extra_input ? this.search_value_extra_input : this.search_value)

			let	value_label = this.search_value_extra_input ? this.search_value_extra_input : this.search_value
			if (this.search_by_missing_value){
				if (field != 'data_field'){
					value_label = 'None'
				}else{
					field = value
					value = 'None'
				}
			}else{
				value_label = value_label.label ? value_label.label : value_label
			}

			let data = {
				field,
				value,
				search_by_missing_value: this.search_by_missing_value,
				field_label: this.search_value_extra_input ? this.search_value.label: this.field_to_search.label,
				value_label
			}
			this.$emit("applySearch", data)
		},
		changeToSystemField(field){
			let field_name = null
			if (['SINGLESELECT', 'MULTISELECT'].includes(field.option_type) || !field.option_type){
				field_name = field.id
			} else {
				field_name = field.option_type
			}
			return field_name
		},
		changeToSystemValue(field_value){
			if (field_value instanceof Object){
				if (this.field_to_search.option_type == "MULTISELECT"){
					field_value = field_value['value']
				} else {
					field_value = field_value['id']
				}
			}
			let formatted_value = null
			let change_to_system_values = {
				'Yes': true,
				'No': false,
				'Less than 1 month': '1_month_ago',
				'1 to 3 months ago': 'between_1_and_3_months',
				'3 to 6 months ago': 'between_3_and_6_months',
				'6 to 12 months ago': 'between_6_and_12_months',
				'More than 12 months': 'more_than_12_months',
				'Admin': 'ADMIN',
				'Operator': 'OPERATOR',
				'Sender': 'SENDER',
				'Data': 'DATA',
				'Contact': 'CONTACT',
				'active': true,
				'inactive': false,
			}
			if (['Never changed', 'Never logged in'].includes(field_value)){
				formatted_value = undefined
			} else {
				formatted_value = change_to_system_values[field_value]
				if (formatted_value == undefined){
					formatted_value = field_value
				}
			}
			return formatted_value
		},
		buildFilterOptions() {
			let filter_options = [
				{
					'option_type':'telephony_id',
					'type':'input_field',
					'label': 'Telephony ID',
					'category': 'System Fields'
				},
				{
					'option_type':'is_active',
					'type':'radio_field',
					'label': 'Status',
					'category': 'System Fields',
					'choices': ['active', 'inactive']
				},
				{
					'option_type':'customer_permission',
					'type':'select_field',
					'label': 'Permission',
					'category': 'System Fields',
					'choices': ['Admin', 'Contact', 'Data', 'Operator', 'Sender']
				},
			];
			let date_range_choices = ['Less than 1 month', '1 to 3 months ago', '3 to 6 months ago', '6 to 12 months ago', 'More than 12 months']
			if (this.logged_user.customer_permission == "ADMIN"){
				filter_options.push(
					{
						'option_type':'last_activity',
						'type':'select_field',
						'label': 'Last Activity',
						'category': 'System Fields',
						'choices': [...date_range_choices, 'Never logged in']
					},
					{
						'option_type':'last_password_change',
						'type':'select_field',
						'label': 'Last Password Change',
						'category': 'System Fields',
						'choices': [...date_range_choices, 'Never changed']
					},
					{
						'option_type':'is_self_registered',
						'type':'radio_field',
						'label': 'Self Registered',
						'category': 'System Fields',
						'choices': ['Yes', 'No']
					},
					{
						'option_type':'date_registered',
						'type':'select_field',
						'label': 'Date of Registration',
						'category': 'System Fields',
						'choices': [...date_range_choices]

					},
					{
						'option_type':'consent_confirmation',
						'type':'radio_field',
						'label': 'Consent confirmation',
						'category': 'System Fields',
						'choices': ['Yes', 'No']
					},
					{
						'option_type':'is_message_recipient',
						'type':'radio_field',
						'label': 'Message Recipient User',
						'category': 'System Fields',
						'choices': ['Yes', 'No']
					}
				)
			};

			filter_options.push(
				{
					'option_type':'address_string',
					'type':'input_field',
					'label': "User Name",
					'category': 'Customer Fields'
				},
				{
					'option_type':'email',
					'type':'input_field',
					'label': 'Email',
					'category': 'Customer Fields'
				},
				{
					'option_type':'phone',
					'type':'input_field',
					'label': 'Phone Number',
					'category': 'Customer Fields'
				},
				{
					'option_type':'data_field',
					'type':'select_field',
					'label': 'Data Field',
					'choices':[],
					'category': 'Customer Fields',
				},
			)

			for (let i=0; i < this.logged_user.descendant_fields.length; i++) {
				if(this.logged_user.descendant_fields[i].field_type == 'MULTISELECT' || this.logged_user.descendant_fields[i].field_type == 'SINGLESELECT'){
					let item = {
						'option_type': this.logged_user.descendant_fields[i].field_type,
						'type': 'select_field',
						'label': this.logged_user.descendant_fields[i].label,
						'choices': this.logged_user.descendant_fields[i].options.map(el => {return {...el, label: el.value}}),
						'id': this.logged_user.descendant_fields[i].id,
						'category': this.logged_user.descendant_fields[i].label === 'Reports Recipients' ? 'System Fields' : 'Customer Fields'
					}
					if (this.logged_user.descendant_fields[i].label == 'Reports Recipients'){
						filter_options.splice(1, 0, item)
					} else {
						filter_options.push(item)
					}
				}
				if (
					this.logged_user.descendant_fields[i].field_type == 'TEXT' ||
					this.logged_user.descendant_fields[i].field_type == 'NUMERIC' ||
					this.logged_user.descendant_fields[i].field_type == 'POSTCODE'
				) {
					for (let el of filter_options){
						if (el['option_type'] == 'data_field'){
							el.choices.push({
								'id': this.logged_user.descendant_fields[i].id,
								'value': this.logged_user.descendant_fields[i].label,
								'label': this.logged_user.descendant_fields[i].label
							})
						}
					}
				}
			}
			filter_options = filter_options.sort((a, b) => a.label.localeCompare(b.label))
			let system_fields = {name: "System Fields", options: []}
			let customer_fields = {name: "Customer Fields", options: []}
			for (let option of filter_options){
				option.value = option.label
				if (option.category == 'System Fields'){
					system_fields.options.push(option)
				} else {
					customer_fields.options.push(option)
				}
			}
			let oredered_filter_options = []
			if (!this.search_by_missing_value){
				oredered_filter_options.push(system_fields)
			}
			oredered_filter_options.push(customer_fields)

			return oredered_filter_options;
		}
	},
	created(){
		this.field_to_search_options = this.buildFilterOptions()
	},
	watch: {
		field_to_search: function(value){
			if (!value){
				return
			}
			this.options_for_select_field = []
			this.selected_field_type = value.type
			this.radio_option_1 = null
			this.radio_option_2 = null
			this.is_extra_input = false
			this.search_value = null
			this.search_value_extra_input = null
			if (value.type == 'select_field'){
				this.options_for_select_field = value.choices.sort((a, b) => {
					if (a.label) {
						a.label.localeCompare(b.label)
					} else {
						a.localeCompare(b)
					}
			})
			} else if (value.type == 'radio_field'){
				this.radio_option_1 = value.choices[0],
				this.radio_option_2 = value.choices[1]
			}
		},
		search_value: function(value){
			if (this.field_to_search.option_type == 'data_field' && value){
				this.is_extra_input = true
			}
		},
		search_by_missing_value: function(value){
			this.field_to_search = null
			this.field_to_search_options = this.buildFilterOptions()
		}
	}
}
</script>
