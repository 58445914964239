<template>
	<div>
		<q-dialog
			v-model="open_export_dialog"
			transition-show="scale"
			transition-hide="scale"
			@hide="$emit('closeDialog')"
		>
			<q-card style="width: 500px; max-width: 80vw;">
				<q-card-section class="text-h5 row items-center q-pb-none">
					<span>Export Users</span>
				</q-card-section>
				<q-card-section class="column items-top">
					<q-toggle
						v-model="basic_data_only"
						label="Basic data only"
					/>
					<q-radio v-model="export_all_users" :val="false" label="Filtered users only" />
					<q-radio v-model="export_all_users" :val="true" label="All users" />
				</q-card-section>
				<q-card-actions align="right">
					<q-btn flat label="Cancel" color="primary" @click="$emit('closeDialog')" />
					<q-btn flat label="Export" color="primary" @click="exportUsers" />
				</q-card-actions>
			</q-card>
		</q-dialog>
	</div>
</template>

<script>

import { mapGetters } from "vuex";
import UserAPI from '@/services/api/Users.js'

export default {
	name: 'ExportUserDialog',
	props: {
		selected_dataset_id: Number,
		fetch_all: Boolean,
		search_filter_data: Object,
		filter: String,
	},
	data() {
		return {
			open_export_dialog: true,
			basic_data_only: true,
			export_all_users: false,
		}
	},
	computed: {
		...mapGetters(['logged_user'])
	},
	methods: {
		async exportUsers(){
			let data = {basic_data_only: this.basic_data_only}
			if (!this.export_all_users){
				data['user_ids'] = await UserAPI.getUsersForExport(
					this.selected_dataset_id, this.fetch_all,
					this.search_filter_data, this.filter
				)
			}
			let response = await UserAPI.exportUsers(data)
			if (response.error) {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			} else {
				this.$q.notify({
					timeout: 6700,
					message: `Your user export was scheduled with ID ${JSON.parse(response)['file_id']}.
					You will receive a message with the file on ${this.logged_user.email}.`,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			}
			this.$emit('closeDialog')
		},
	}
}
</script>