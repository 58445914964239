<template>
	<div>
		<q-dialog
			v-model="add_user_dialog"
			transition-show="scale"
			transition-hide="scale"
			@hide="$emit('closeAddDialog')"
		>
			<q-card style="width: 500px; max-width: 80vw;">
				<q-card-section>
					<div class="text-h5">Create user</div>
				</q-card-section>
				<div class="q-pa-md">
					<q-select
						class="q-mb-md"
						v-model="dataset"
						label="Select dataset:"
						:options="dataset_options"
						:error="dataset_error"
						:error-message="'This field is required'"
						@input="dataset_error = false"
					>
					</q-select>
					<q-select
						class="q-mb-md"
						v-model="customer_permission"
						label="Select Permission"
						:options="customer_permission_options"
						:error="customer_permission_error"
						:error-message="'This field is required'"
						@input="customer_permission_error = false"
					/>
					<q-input
						class="q-mb-md"
						v-model="email"
						label="Enter email address"
						:error="email_error"
						:error-message="email_error_message"
						@input="validateEmail"
					/>
					<q-input
						class="q-mb-md"
						v-if="customer_permission == 'CONTACT'"
						v-model="external_id"
						label="Enter External ID"
						:error="external_id_error"
						:error-message="'This field is required'"
						@input="emptyErrorMessages"
					/>
				</div>
				<q-card-actions align="right" class="bg-white text-primary">
					<q-btn flat label="Cancel" @click="$emit('closeAddDialog')" />
					<q-btn flat label="Create" @click="createUser"/>
				</q-card-actions>
			</q-card>
		</q-dialog>
	</div>
</template>

<script>

import { mapGetters } from "vuex";
import UserAPI from '@/services/api/Users.js'

export default {
	name: 'AddUser',
	data() {
		return {
			add_user_dialog: true,
			dataset: null,
			dataset_options: [],
			customer_permission: null,
			customer_permission_options: [
				"ADMIN",
				"OPERATOR",
				"DATA",
				"SENDER",
				"CONTACT"
			],
			email: null,
			external_id: null,
			dataset_error: false,
			customer_permission_error: false,
			email_error: false,
			email_error_message: '',
			external_id_error: false,
		}
	},
	computed: {
		...mapGetters(['customer_groups']),
	},
	methods: {
		validateEmail(){
			if (!this.email){
				return
			}
			let re = /\S+@\S+\.\S+/;
			if (!re.test(this.email)){
				this.email_error = true
				this.email_error_message = "Invalid Email"
			} else {
				this.email_error = false
				this.external_id_error = false
			}
		},
		async createUser(){
			let has_error = this.validateData()
			if (has_error){
				return
			}
			let response = await UserAPI.createUser({
				customer_group: this.dataset.id,
				customer_permission: this.customer_permission,
				email: this.email,
				external_id: this.external_id
			})

			if (response.error) {
				let error_message = ''
				if (typeof response.error_message == 'string'){
					error_message = response.error_message
				} else {
					error_message = `${Object.values(response.error_message)[0]}`
					error_message = error_message.charAt(0).toUpperCase() + error_message.slice(1);
				}
				this.$q.notify({
					timeout: 6700,
					message: error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			this.$q.loading.show({
				message: 'The user is being created'
			})

			this.timer = setTimeout(() => {
				this.$q.loading.hide()
				this.$q.notify('User created successfully.')
				this.$router.push(`/users/${response.user_id}/edit/`)
			}, 3000)
		},
		async showLoading () {

		},
		validateData(){
			if (!this.dataset){
				this.dataset_error = true
			}
			if (!this.customer_permission){
				this.customer_permission_error = true
			}
			if (!this.email){
				if(this.customer_permission == "CONTACT"){
					if (!this.external_id){
						this.email_error = true
						this.email_error_message = "This field is required"
					}
				} else {
					this.email_error = true
					this.email_error_message = "This field is required"
				}
			}
			if (!this.external_id){
				if(this.customer_permission == "CONTACT"){
					if (!this.email){
						this.external_id_error = true
					}
				}
			}
			return this.dataset_error || this.customer_permission_error || this.email_error || this.external_id_error
		},
		emptyErrorMessages(){
			this.external_id_error = false
			this.email_error = false
			this.email_error_message = ''
		}
	},
	created(){
		this.dataset_options = this.customer_groups.map(el => {return {id: el.id, label: el.name}})
	},
	watch: {
		customer_permission: function(value){
			if (value != 'CONTACT'){
				this.external_id_error = false
			}
		}
	}
}
</script>
