<template>
	<div :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'">
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Manage users
		</h1>
		<div class="row" style="padding-bottom: 1rem">
			<q-table
				title="Users"
				:data="rows"
				:columns="headers"
				row-key="name"
				style="width: 100%"
				:hide-pagination="false"
				:hide-selected-banner="false"
				:loading="loading"
				:pagination.sync="pagination"
				binary-state-sort
				:filter="filter"
				:rows-per-page-options="[20, 50, 100, 0]"
				@request="onRequest"
			>
				<template v-slot:top>
					<div style="width:100%">
						<div class="row">
							<div class="flex justify-start items-center col-md-6 col-12">
								<q-btn icon="filter_list" @click="filter_dialog=true" round flat>
									<q-tooltip anchor="top middle" :delay="500">
										Filter
									</q-tooltip>
								</q-btn>
								<q-chip
									removable
									v-model="search_filter_chip"
									color="primary"
									outline
									text-color="white"
									:label="search_filter_chip_label"
									:title="search_filter_chip_label"
									@remove="removeSearchFilterData"
								/>
							</div>
							<div class="col-md-6 col-12 select-dataset">
								<q-select
									v-model="selected_dataset"
									:options="filter_options"
									:label="'Select dataset'"
									@input="changeDataset"
									:error="has_select_dataset_error"
									:error-message="select_dataset_error_messag"
									@filter="filterFn"
									use-input
								/>
							</div>
						</div>
						<div class="flex" :class="$q.screen.gt.sm ? 'row justify-between' : 'column reverse'">
							<div class="q-mt-md col-md-6 col-12 flex justify-start items-center">
								<q-btn
									color="primary"
									label="Create User"
									:style="$q.screen.lt.md ? 'max-width:100%; width:100%;' : ''"
									@click="add_user_dialog=true"
								/>
							</div>
							<div class="q-mt-md col-md-6 col-12">
								<q-input
									v-model="filter"
									label="Search by user"
									outlined
									:hint="filter && filter.length < 3 ? 'Enter at least 3 characters' : ''"
								>
									<template v-slot:append>
										<q-icon name="search" />
									</template>
								</q-input>
							</div>
						</div>
					</div>
				</template>
				<template v-slot:body-cell-buttons='item'>
					<q-td>
						<div class='material-icons-outlined'>
							<q-btn
								color='dark'
								icon="o_edit"
								round
								flat
								@click="editUser(item.row)"
								>
									<q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
										Edit user
									</q-tooltip>
								</q-btn>
							<q-btn
								color='dark'
								icon="o_delete"
								round
								flat
								@click="delete_user = item.row; delete_user_dialog=true"
							>
								<q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
									Delete user
								</q-tooltip>
							</q-btn>
						</div>
					</q-td>
				</template>
				<template v-slot:pagination>
					<span class="row items-center justify-end">
						<q-pagination
							v-model="pagination.page"
							boundary-links
							direction-links
							:max="pagesNumber"
							:max-pages="5"
							size="md"
							color="grey-8"
							active-color="primary"
							class="pagination-wrap"
							@click="pageNumber(pagination.page)"
						/>
					</span>
				</template>
			</q-table>
			<search-dialog
				v-if="filter_dialog"
				@applySearch="applySearch"
				@closeDialog="filter_dialog=false"
			/>
			<add-user-dialog
				v-if="add_user_dialog"
				@closeAddDialog="add_user_dialog = false"
			/>
			<export-user-dialog
				v-if="open_export_dialog"
				:selected_dataset_id ="selected_dataset_id"
				:fetch_all="fetch_all"
				:search_filter_data="search_filter_data"
				:filter="filter"
				@closeDialog="open_export_dialog = false"
			/>
			<delete-user-dialog
				v-if="delete_user_dialog"
				:delete_user='delete_user'
				@deleteUser='deleteUser'
				@closeDialog='delete_user_dialog = false'
			/>
		</div>
		<q-item class="text-left q-py-none q-pl-none">
			You have {{ total_number_of_users }} users
		</q-item>
		<q-fab
			class="flex justify-end q-mt-md"
			style="position: sticky; bottom: 50px; z-index: 1;"
			:label="$q.screen.gt.sm ? 'Actions' : ''"
			label-position="left"
			color="primary"
			icon="keyboard_arrow_up"
			direction="up"
		>

			<q-fab-action
				color="primary"
				icon="file_upload"
				:label="$q.screen.gt.sm ? 'Import users' : ''"
				@click="$router.push('/user_import/')"
			>
				<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
					Import users
				</q-tooltip>
			</q-fab-action>
			<q-fab-action
				v-if="current_CG.root_customer_group_permissions.includes('On Call core platform access')"
				color="primary"
				icon="perm_contact_calendar"
				:label="$q.screen.gt.sm ? 'Import on call rotation' : ''"
				@click="$router.push('/user_import_on_call/')"
			>
				<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
					Import on call rotation
				</q-tooltip>
			</q-fab-action>
			<q-fab-action
				color="primary"
				icon="file_download"
				:label="$q.screen.gt.sm ? 'Export' : ''"
				@click="open_export_dialog = true"
			>
				<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
					Export
				</q-tooltip>
			</q-fab-action>
		</q-fab>
	</div>
</template>

<script>
import UserAPI from '@/services/api/Users.js'
import SearchDialog from '@/components/users/dialogs/SearchDialog.vue'
import AddUserDialog from '@/components/users/dialogs/AddUserDialog.vue'
import ExportUserDialog from '@/components/users/dialogs/ExportUserDialog.vue'
import DeleteUserDialog from '@/components/users/dialogs/DeleteUserDialog.vue'

import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'List',
	meta: {
		title: `Alert Cascade - Manage Users`
	},
	components: { UserAPI, SearchDialog, AddUserDialog, ExportUserDialog, DeleteUserDialog },
	data () {
		return {
			rows: [],
			selected_dataset: "",
			dataset_options: [],
			selected_dataset_id: '',
			filter_dialog: false,
			add_user_dialog: false,
			search_filter_data: null,
			pagination: {
				rowsPerPage: 20,
				rowsNumber: 0,
				descending: true,
				sortBy: 'address_string',
				page: 1,
			},
			pagesNumber: 0,
			search_filter_chip: false,
			search_filter_chip_label: "",
			delete_user: {},
			delete_user_dialog: false,
			open_export_dialog: false,
			max_pages: 0,
			loading: false,
			filter: '',
			filter_options: this.dataset_options,
			has_select_dataset_error: false,
			select_dataset_error_messag: '',
			fetch_all: false,
			total_number_of_users: 0
		}
	},
	computed: {
		...mapGetters(['logged_user', 'customer_groups', 'current_CG']),
	},
	methods: {
		...mapActions(['getCustomerGroups']),
		async onRequest(props) {
			const { sortBy, descending, rowsPerPage, page, rowsNumber } = props.pagination
			const filter = props.filter
			this.has_filter_error = false
			this.filter_error_message= ''
			if (filter && [1, 2].includes(this.filter.length)){
				return
			}else if (sortBy || descending || rowsPerPage || page){
				this.pagination.rowsPerPage = rowsPerPage
				this.pagination.rowsNumber = rowsNumber
				this.pagination.descending = descending
				this.pagination.sortBy = sortBy
				this.pagination.page = 1
			}
			await this.getUsers()
		},
		async pageNumber(page_number){
			this.pagination.page = page_number
			await this.getUsers()
		},
		async changeDataset(){
			this.pagination.page = 1
			await this.getUsers()
		},
		async getUsers(){
			this.has_select_dataset_error = false
			this.select_dataset_error_messag = ""
			if (!this.selected_dataset){
				this.has_select_dataset_error = true
				this.select_dataset_error_messag = "Please select dataset"
				return
			}
			this.selected_dataset_id = this.logged_user.customer_group
			this.fetch_all = false
			if(this.selected_dataset == 'All'){
				this.selected_dataset_id = this.logged_user.customer_group
				this.fetch_all = true
			} else {
				this.selected_dataset_id = this.selected_dataset.id
			}

			this.loading = true
			let response = await UserAPI.getUsersForDataset(
				this.selected_dataset_id, this.fetch_all, this.search_filter_data,
				this.pagination, this.filter
			)
			this.rows = response.serialized_data
			this.pagesNumber = response.number_of_pages
			this.loading = false
			this.total_number_of_users = response.count
		},
		async applySearch(data){
			this.search_filter_data = data
			this.pagination.page = 1
			await this.getUsers()
			this.filter_dialog = false
			this.search_filter_chip = true

			if (data.search_by_missing_value && data.field_label == 'Data Field'){
				this.search_filter_chip_label = `${data.value_label.label}: None`
			}else{
				this.search_filter_chip_label = `${data.field_label}: ${data.value_label}`
			}
		},
		async removeSearchFilterData(){
			this.search_filter_data = null
			this.search_filter_chip = false
			this.search_filter_chip_label = ""
			await this.getUsers()
		},
		editUser(user){
			let route = this.$router.resolve({path: `/users/${user.id}/edit/`});
			window.open(route.href, '_blank');
		},
		async deleteUser(){
			this.delete_user_dialog = false
			this.delete_user = {}
			await this.getUsers()
		},
		numberOfPages(value){
			if (this.pagination.rowsPerPage != 0){
				return Math.ceil(value / this.pagination.rowsPerPage)
			}else{
				return 1
			}
		},
		filterFn (val, update, type) {
			if (val === '') {
				update(() => {
					this.filter_options = this.dataset_options
				})
				return
			}
			update(() => {
				const needle = val.toLowerCase()
			    this.filter_options = this.filter_options.filter((option) => {
					if (option.label){
						return option.label.toLowerCase().indexOf(needle) > -1
					}else{
						return option.toLowerCase().indexOf(needle) > -1
					}
				})
			})
		}
	},
	async created(){
		this.$q.loading.show({
			delay: 400,
		})
		this.headers =[
			{name: "address_string", field: "address_string", label: "User", align: 'left', sortable: true},
			{name: "email", field: "email", label: "Email", align: 'left', sortable: true},
			{
				name: "customer_group",
				field: obj => {
					return this.customer_groups.filter(el => el.id == obj.customer_group)[0].name
				},
				label: "Dataset",
				align: 'left',
				sortable: true
			},
			{name: "customer_permission", field: "customer_permission", label: "Permission", align: 'left', sortable: true},
			{name: "buttons", field: "icons", label: "Actions", align: "center"}
		]
		this.selected_dataset = "All"
		await this.getCustomerGroups()
		this.dataset_options = this.customer_groups.map(el => {return {id: el.id, label: el.name}})
		this.dataset_options.push(this.selected_dataset)
		await this.getUsers()
		this.$q.loading.hide()
	},
}
</script>

<style lang="scss">
.cg_list {
	margin-top: 2rem;
	padding: 1rem;
	@media (max-width: 1536px) {
		max-width: 75%;
		margin-left: auto;
		margin-right: auto;
	}
}
.pagination-wrap{
	flex-wrap: wrap;
}
@media screen and (max-width: 1023px) {
	.add-usr-btn{
		justify-content: center;
	}
	.select-dataset{
		padding-left: 0.285em;
		margin-left: 4px;
	}
}
</style>

