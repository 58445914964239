<template>
	<div>
		<q-dialog v-model="delete_user_dialog" @hide="$emit('closeDialog')">
			<q-card>
				<q-card-section class="row items-center">
					<span>Are you sure you want to delete <b>{{ delete_user.address_string }}</b>?</span>
				</q-card-section>
				<q-card-actions align="right">
					<q-btn flat label="Cancel" color="primary" @click="$emit('closeDialog')" />
					<q-btn flat label="Delete" color="primary" @click="deleteUser" />
				</q-card-actions>
			</q-card>
		</q-dialog>
	</div>
</template>

<script>

import UserAPI from '@/services/api/Users.js'

export default {
	name: 'DeleteUserDialog',
	props: {
		delete_user: Object
	},
	data() {
		return {
			delete_user_dialog: true,
		}
	},
	methods: {
		async deleteUser(){
			let response = await UserAPI.deleteUser(this.delete_user.id)
			if (response.error) {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			} else {
				this.$q.notify('User deleted successfully.')
			}
			this.$emit("deleteUser")
		}
	},
}
</script>
